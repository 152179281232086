import { template as template_2342b8b464f84052afd00d1d13bbcb2c } from "@ember/template-compiler";
const WelcomeHeader = template_2342b8b464f84052afd00d1d13bbcb2c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_481da469600041e0918b738647fbe664 } from "@ember/template-compiler";
const FKControlMenuContainer = template_481da469600041e0918b738647fbe664(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

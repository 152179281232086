import { template as template_07af9839af414150bc74c41a6d42c3bf } from "@ember/template-compiler";
const FKLabel = template_07af9839af414150bc74c41a6d42c3bf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

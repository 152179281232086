import { template as template_31f78945d57c46508b9031891268ff13 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_31f78945d57c46508b9031891268ff13(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
